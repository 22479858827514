<template>
    <div class="sld_chat_history">
        <div class="top_header">
            <p class="header_title">{{ L['聊天记录'] }}</p>
        </div>

        <div class="main_acc_top">
            <template v-if="identity == 'seller'">
                <el-scrollbar id="el-scrollbar_top">
                    <div :class="{ acc_top_item: true, csOn: index == cacIdx }" v-for="(item, index) in adminlist" :key="index"
                        @click="change(index, item)" :title="item.vendorName">
                        {{ item.vendorName ? item.vendorName : item.storeName }}</div>
                    <span></span>
                </el-scrollbar>
            </template>


            <template v-else-if="identity == 'admin' && isSuper == true">
                <div :class="{ acc_top_item: true, csOn: 0 == cacIdx }" @click="change(0, item)">平台</div>
                <div :class="{ acc_top_item: true, csOn: 1 == cacIdx }" @click="change(1, item)">商家</div>
            </template>


        </div>

        <div class="middle_bar" v-if="showUnderAdmin">
            <div class="acc_name flex_row_start_center">
                <span style="margin: 0 14px;">商家:</span>
                <el-select v-model="storeChoose" placeholder="请选择商家" @change="adminStoChange" filterable>
                    <el-option v-for="(item, index) in adminlist" :key="index" :label="item.storeName"
                        :value="item.storeId">
                    </el-option>
                </el-select>
            </div>
        </div>

        <div class="middle_bar">

            <div class="acc_name" v-if="isStoreAdmin == 1">
                <span :title="vendorName">{{ vendorName }}</span><span>{{ L['聊天记录'] }}</span>
            </div>

            <div class="acc_name flex_row_start_center" v-if="identity == 'admin' && adminVenList.length && isSuper == true">
                <span style="margin: 0 14px;">客服:</span>
                <el-select v-model="vendorChoose" placeholder="请选择客服" @change="adminVenChange">
                    <el-option v-for="(item, index) in adminVenList" :key="index" :label="item.vendorName"
                        :value="item.vendorId">
                    </el-option>
                </el-select>
            </div>


            <div class="chat_content">
                <span>{{ L['消息内容'] }}：</span>
                <el-input type="text" :placeholder="L['请输入消息内容']" v-model="msgFilter" clearable @clear="clearContent">
                </el-input>
            </div>

            <div class="chat_date">
                <span>{{ L['聊天日期'] }}：</span>
                <el-date-picker v-model="dateRange" type="daterange" range-separator="-" :start-placeholder="L['开始日期']"
                    :end-placeholder="L['结束日期']" @clear="clearContent" :disabled-date="disabledDate">
                </el-date-picker>
            </div>

            <button class="query" @click="query">{{ L['查询'] }}</button>
        </div>

        <div class="chat_con" :style="`height:${clientHeight - 233}px`">


            <div class="chat_list">

                <div class="search">
                    <div class="search_wrap">
                        <i class="el-icon-search"></i>
                        <input type="text" id="store_search" :placeholder="L['搜索最近联系人']" v-model="searchMember"
                            @input="didSearch = false">
                        <i class="el-icon-close" @click="clearInput" v-show="searchMember"></i>
                        <div @click="searchMemberList" class="button">{{ L['搜索'] }}</div>
                    </div>
                </div>

                <div :style="`height:${clientHeight - 300}px`">
                    <el-scrollbar>
                        <!-- 左侧聊天会员列表start -->
                        <div class="left_list" v-infinite-scroll="loadlList">
                            <div class="left_list_item" v-for="(item, index) in chatMemberList.list" :key="index"
                                @click="chatInit(item.memberId, item.storeId)"
                                :style="{ 'background-color': item.memberId == GlobalMemberId && item.storeId == GlobalStoreId ? '#F5F5F5' : '#fff' }">
                                <img :src="item.memberAvatar" alt="">
                                <p class="item_name">{{ item.memberName }}</p>

                            </div>
                            <loadingState v-if="loadState == 'first_loading' || chatMemberList.list.length > 0"
                                :state='loadState' />
                        </div>
                        <div class="empty_data_left" v-if="!chatMemberList.list.length > 0">
                            <img src="@/assets/goods/empty_data.png" alt="">
                            <p>{{ L['暂无数据～'] }}</p>
                        </div>
                        <!-- 左侧聊天会员列表end -->
                    </el-scrollbar>
                </div>
            </div>


            <div class="chat_window" :style="`height:${clientHeight - 233}px`">
                <el-scrollbar ref="scroll" id="chat">
                    <template v-if="msgList.list.length > 0">
                        <div v-for="(item, index) in msgList.list" :key="index">
                            <div v-if="index == 0 || (index > 0 && $isShowTime(msgList.list[index - 1].addTime, item.addTime))"
                                class="time_line">
                                {{ $formatChatTime(item.addTime) }}
                            </div>
                            <!-- 会员发送的信息start -->
                            <template v-if="item.userType == 1">
                                <!-- 文本类型start -->
                                <div class="chat_ladder user" v-if="item.msgType == 1">
                                    <img :src="item.memberAvatar" class="conten_left">
                                    <div class="content_right">
                                        <p>{{ item.memberName }}</p>
                                        <span class="content_text text_type"
                                            v-html="JSON.parse(item.msgContent).content"></span>
                                    </div>
                                </div>
                                <!-- 文本类型end -->

                                <!-- 图片类型start -->
                                <div class="chat_ladder user" v-if="item.msgType == 2">
                                    <img :src="item.memberAvatar" class="conten_left">
                                    <div class="content_right">
                                        <p class="name">{{ item.memberName }}</p>
                                        <div class="content_text image_type">
                                            <el-image :src="JSON.parse(item.msgContent).pic"
                                                :preview-src-list="[JSON.parse(item.msgContent).pic]"
                                                hide-on-click-modal="true">
                                                <template #placeholder>
                                                    <div class="image-slot">
                                                        <i class="el-icon-picture-outline"></i>
                                                    </div>
                                                </template>
                                            </el-image>
                                        </div>
                                    </div>
                                </div>
                                <!-- 图片类型end -->

                                <!-- 商品类型start -->
                                <div class="chat_ladder user" v-if="item.msgType == 3">
                                    <img :src="item.memberAvatar" class="conten_left">
                                    <div class="content_right">
                                        <p class="name">{{ item.memberName }}</p>
                                        <div class="content_text goods_type">
                                            <div class="goods_model" :key="index">
                                                <img :src="JSON.parse(item.msgContent).goodsImage" alt="">
                                                <div class="goods_info">
                                                    <div class="goods_info_title">
                                                        {{ JSON.parse(item.msgContent).goodsName }}
                                                    </div>
                                                    <div class="goods_info_bottom">
                                                        <span>￥{{ JSON.parse(item.msgContent).goodsPrice }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 商品类型end -->

                                <!-- 订单类型start -->
                                <div class="chat_ladder user" v-if="item.msgType == 4">
                                    <img :src="item.memberAvatar" class="conten_left">
                                    <div class="content_right">
                                        <p class="name">{{ item.memberName }}</p>
                                        <div class="content_text order_type">
                                            <div class="order_title">
                                                <span
                                                    class="order_sn">{{ L['订单号'] }}：<i>{{ JSON.parse(item.msgContent).orderSn }}</i></span>
                                                <span
                                                    class="order_time">{{ JSON.parse(item.msgContent).createTime }}</span>
                                            </div>
                                            <div class="goods_model order_type">
                                                <img :src="JSON.parse(item.msgContent).goodsImage" alt="">
                                                <div class="goods_info">
                                                    <div class="goods_info_title">
                                                        {{ JSON.parse(item.msgContent).goodsName }}
                                                    </div>
                                                    <div class="goods_info_bottom goods_info_bottom_detial">
                                                        <span>￥{{ JSON.parse(item.msgContent).productShowPrice ? JSON.parse(item.msgContent).productShowPrice : JSON.parse(item.msgContent).goodsPrice }}</span>
                                                        <span
                                                            class="goods_info_bottom_ok">{{ JSON.parse(item.msgContent).orderStateValue }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 订单类型end -->
                            </template>
                            <!-- 会员发送的信息end -->


                            <!-- 客服发送的消息start -->
                            <template v-if="item.userType == 2">
                                <!-- 文本类型start -->
                                <div class="chat_ladder merchant" v-if="item.msgType == 1">
                                    <div class="content_right">
                                        <p class="name">{{ item.vendorName }}</p>
                                        <span class="content_text text_type"
                                            v-html="JSON.parse(item.msgContent).content"></span>
                                    </div>
                                    <img :src="item.vendorAvatar" alt="" class="user_avatar">
                                </div>
                                <!-- 文本类型end -->

                                <!-- 商品类型start -->
                                <div class="chat_ladder merchant" v-if="item.msgType == 3">
                                    <div class="content_right">
                                        <p class="name">{{ item.vendorName }}</p>
                                        <div class="content_text goods_type">
                                            <div class="goods_model" :key="index">
                                                <img :src="JSON.parse(item.msgContent).goodsImage" alt="">
                                                <div class="goods_info">
                                                    <div class="goods_info_title">
                                                        {{ JSON.parse(item.msgContent).goodsName }}
                                                    </div>
                                                    <div class="goods_info_bottom">
                                                        <span>￥{{ JSON.parse(item.msgContent).goodsPrice }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <img :src="item.vendorAvatar" alt="" class="user_avatar">
                                </div>
                                <!-- 商品类型end -->


                                <!-- 订单类型start -->
                                <div class="chat_ladder merchant" v-if="item.msgType == 4">
                                    <div class="content_right">
                                        <p class="name">{{ item.vendorName }}</p>
                                        <div class="content_text order_type">
                                            <div class="order_title">
                                                <span
                                                    class="order_sn">{{ L['订单号'] }}：<i>{{ JSON.parse(item.msgContent).orderSn }}</i></span>
                                                <span
                                                    class="order_time">{{ JSON.parse(item.msgContent).createTime }}</span>
                                            </div>
                                            <div class="goods_model order_type">
                                                <img :src="JSON.parse(item.msgContent).goodsImage" alt="">
                                                <div class="goods_info">
                                                    <div class="goods_info_title">
                                                        {{ JSON.parse(item.msgContent).goodsName }}
                                                    </div>
                                                    <div class="goods_info_bottom goods_info_bottom_detial">
                                                        <span>￥{{ JSON.parse(item.msgContent).goodsPrice }}</span>
                                                        <span
                                                            class="goods_info_bottom_ok">{{ JSON.parse(item.msgContent).orderStateValue }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <img :src="item.vendorAvatar" alt="" class="user_avatar">
                                </div>
                                <!-- 订单类型end -->

                                <!-- 图片类型start -->
                                <div class="chat_ladder merchant" v-if="item.msgType == 2">
                                    <div class="content_right">
                                        <p class="name">{{ item.vendorName }}</p>
                                        <div class="content_text image_type">
                                            <el-image :src="JSON.parse(item.msgContent).pic"
                                                :preview-src-list="[JSON.parse(item.msgContent).pic]"
                                                hide-on-click-modal="true">
                                                <template #placeholder>
                                                    <div class="image-slot">
                                                        <i class="el-icon-picture-outline"></i>
                                                    </div>
                                                </template>
                                            </el-image>
                                        </div>
                                    </div>
                                    <img :src="item.vendorAvatar" alt="" class="user_avatar">
                                </div>
                                <!-- 图片类型end -->

                            </template>
                            <!-- 客服发送的消息end -->
                        </div>
                    </template>
                    <template v-else>
                        <div class="empty_data_left">
                            <img src="@/assets/goods/empty_data.png" alt="">
                            <p>{{ L['暂无数据～'] }}</p>
                        </div>
                    </template>
                    <!-- 这里加个div标签是防止一开始出现‘can not read property 0 of null’的错误 -->
                    <div></div>
                </el-scrollbar>
            </div>


        </div>
    </div>
</template>

<script>
import { onMounted, getCurrentInstance, reactive, ref, watch } from 'vue'
import loadingState from '@/components/loadingState'
// import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    name: 'chatHistory',
    components: {
        loadingState
    },
    setup() {
        const GlobalMemberId = ref('')//全局会员Id
        const GlobalStoreId = ref(0)
        const { proxy } = getCurrentInstance()
        const L = proxy.$getCurLanguage()
        const chatMemberList = reactive({//聊天会员列表
            list: []
        })
        const msgList = reactive({//聊天记录列表
            list: []
        })
        // const store = useStore()
        const isStoreAdmin = localStorage.getItem('isStoreAdmin')
        const isSuper = localStorage.getItem('isSuper')
        const searchMember = ref('')
        const dateRange = ref('')//日期起止事件
        const msgFilter = ref('')//消息内容
        const firstLoad = ref(true)
        const cacIdx = ref(0)
        const minMsgId = ref('');//当前消息的最小id
        //点击事件--将memberId赋值给全局变量，获取聊天记录
        const chatInit = (memberId, storeId) => {
            dateRange.value = ''
            startTime.value = ''
            endTime.value = ''
            msgFilter.value = ''
            minMsgId.value = 0
            GlobalMemberId.value = memberId
            GlobalStoreId.value = storeId
            getChatLogList()

        }
        const vendorName = ref('')
        const vendorId = ref(0)
        const curStoreId = ref(null)
        const current = ref(1)
        const clientHeight = ref(0)
        const identity = localStorage.getItem('identity')
        const adminVenList = ref([])
        const vendorChoose = ref('')
        const storeChoose = ref('')
        const showUnderAdmin = ref(false)
        //聊天消息已读
        const msgReadDone = (memberId) => {
            let params = {
                memberId
            }
            proxy.$post(`/v3/helpdesk/${identity}/chat/markRead`, params).then()
        }
        const hasMore = reactive({
            chatList: false,
            chatLog: false
        })
        const loadState = ref('')
        const startTime = ref('')
        const endTime = ref('')
        const scrollTop = ref(false)
        const adminlist = ref([])
        const didSearch = ref(false)
        const firstLoad1 = ref(true)
        // const firstLoad2 = ref(false)
        //获取聊天会员列表
        const getMemberChatList = () => {
            const params = {};
            if (minMsgId.value) {
                params.msgId = minMsgId.value;
            }

            if (vendorId.value) {
                params.vendorId = vendorId.value
            }

            if (vendorChoose.value) {
                params.vendorId = vendorChoose.value
            }


            if (curStoreId.value != null) {
                params.storeId = curStoreId.value
            }
            proxy.$get(`/v3/helpdesk/${identity}/chat/list`, params).then(res => {
                if (res.state == 200) {
                    if (minMsgId.value) {
                        chatMemberList.list = chatMemberList.list.concat(res.data);
                    } else {
                        chatMemberList.list = res.data
                    }

                    hasMore.chatList = res.data.length < 10 ? false : true;
                    if (hasMore.chatList) {
                        loadState.value = 'allow_loading_more';
                    } else {
                        loadState.value = 'no_more_data';
                    }

                    if (chatMemberList.list.length > 0) {
                        GlobalMemberId.value = chatMemberList.list[0].memberId
                        GlobalStoreId.value = chatMemberList.list[0].storeId
                        minMsgId.value = chatMemberList.list[chatMemberList.list.length - 1].msgId
                        if (firstLoad.value) {
                            getChatLogList()
                        }

                    }
                } else {
                    chatMemberList.list = []
                }
            })
        }
        //获取聊天记录列表

        const getChatLogList = () => {
            let params = {}
            if (GlobalMemberId.value) {
                params.memberId = GlobalMemberId.value
            } if (startTime.value) {
                params.startTime = startTime.value + ' 00:00:00'
            } if (endTime.value) {
                params.endTime = endTime.value + ' 23:59:59'
            } if (msgFilter.value) {
                params.msgContent = msgFilter.value
            } if (vendorChoose.value) {
                params.vendorId = vendorChoose.value
            } if (curStoreId.value) {
                params.storeId = curStoreId.value
            } if (vendorId.value) {
                params.vendorId = vendorId.value
            }
            params.current = current.value
            proxy.$get(`/v3/helpdesk/${identity}/chat/logList`, params).then(res => {
                if (res.state == 200) {
                    if (current.value == 1) {
                        msgList.list = res.data.list
                    } else {
                        msgList.list = res.data.list.concat(msgList.list)
                    }
                    let page = res.data.pagination
                    hasMore.chatLog = page.current * page.pageSize < page.total
                    if (hasMore.chatLog) {
                        current.value++
                    }

                }

            })
        }

        //监听admin下店铺选择或者seller下的客服选择
        watch(() => cacIdx.value, (nv, ov) => {
            if (nv != ov) {
                startTime.value = ''
                endTime.value = ''
                dateRange.value = ''
                msgFilter.value = ''

                GlobalMemberId.value = ''
                if (identity == 'seller') {
                    msgList.list = []
                    minMsgId.value = 0
                    curStoreId.value = 0
                    vendorName.value = adminlist.value[nv].vendorName
                    vendorId.value = adminlist.value[nv].vendorId
                    getMemberChatList()
                } else {
                    if (nv == 0) {
                        minMsgId.value = 0
                        curStoreId.value = 0
                        adminVendorList()

                    } else {
                        minMsgId.value = 0
                        msgList.list = []
                        chatMemberList.list = []
                        vendorChoose.value = ''
                        firstLoad1.value = false
                        curStoreId.value = adminlist.value[nv].storeId
                        // adminVendorList()

                        if (storeChoose.value) {
                            adminStoChange()
                        }
                    }


                }
            }
        })

        //监听日期选择
        watch(() => dateRange.value, (nv, ov) => {
            if (nv != ov && nv == null) {
                startTime.value = ''
                endTime.value = ''
                getChatLogList()
            }
        })

        //查询
        const query = () => {


            if (dateRange.value[0]) {
                startTime.value = [new Date(dateRange.value[0]).getFullYear(), new Date(dateRange.value[0]).getMonth() + 1, new Date(dateRange.value[0]).getDate()].map(item => item.toString().length >= 2 ? item : '0' + item).join('-')
            }

            if (dateRange.value[1]) {
                endTime.value = [new Date(dateRange.value[1]).getFullYear(), new Date(dateRange.value[1]).getMonth() + 1, new Date(dateRange.value[1]).getDate()].map(item => item.toString().length >= 2 ? item : '0' + item).join('-')
            }




            if (!msgFilter.value && !startTime.value && !endTime.value) {
                return
            }

            if (identity == 'admin') {
                if (!GlobalMemberId.value) {
                    ElMessage('请先选择会员')
                    return
                }


                if (!vendorChoose.value) {
                    ElMessage('请先选择客服')
                    return
                }
            }






            getChatLogList()
        }

        // 向下滚动至底部加载数据
        const loadlList = () => {
            if (hasMore.value) {
                firstLoad.value = false
                getMemberChatList()
            }
        }

        //滑动至底部方法
        const scrollToBottom = el => {
            el.scrollTop = el.scrollHeight;
            el.scrollTo(0, el.scrollHeight)
        };

        //滚动监听
        const scroll = () => {
            let el = proxy.$refs.scroll.wrap
            let timeout = ''
            el.addEventListener('scroll', () => {
                if (timeout) clearTimeout(timeout);
                timeout = window.setTimeout(function () {
                    if (el.scrollTop == 0 && hasMore.chatLog) {
                        scrollTop.value = true
                        getChatLogList()
                    }
                }, 500);
            }, true);
            (new MutationObserver(() => {
                if (scrollTop.value) {
                    el.scrollTop = el.clientHeight + 400
                    return
                }
                scrollToBottom(el);
            })).observe(el, { childList: true, subtree: true });
        }

        //seller下的获取客服列表
        const sellerList = () => {
            proxy.$get(`/v3/${identity}/${identity}/vendor/keFuList`).then(res => {
                if (res.state == 200) {
                    let res1 = res.data.filter(i => i.isAllowLogin == 1)
                    adminlist.value = res1.sort((a, b) => { return b.isStoreAdmin - a.isStoreAdmin })
                    vendorId.value = adminlist.value[cacIdx.value].vendorId
                    getMemberChatList()
                }
            })
        }

        //admin下获取店铺列表
        const adminList = () => {
            proxy.$get(`v3/helpdesk/admin/chat/storeList`).then(res => {
                if (res.state == 200) {
                    adminlist.value = res.data.filter(i => i.storeId != 0)
                    curStoreId.value = 0
                    adminVendorList()

                }
            })
        }

        //admin的店铺列表下获取客服列表
        const adminVendorList = () => {
            let param = {
                storeId: curStoreId.value
            }

            proxy.$get(`v3/helpdesk/admin/chat/vendorList`, param).then(res => {
                if (res.state == 200) {
                    adminVenList.value = res.data
                    vendorChoose.value = res.data[0].vendorId
                    getMemberChatList()
                }
            })

        }

        const adminVenChange = () => {
            minMsgId.value = 0
            getMemberChatList()
        }

        const adminStoChange = () => {
            curStoreId.value = storeChoose.value
            startTime.value = ''
            endTime.value = ''
            dateRange.value = ''
            msgFilter.value = ''
            minMsgId.value = 0
            GlobalMemberId.value = ''
            msgList.list = []
            adminVendorList()
        }

        const clearContent = () => {
            msgFilter.value = ''
            getChatLogList()
        }

        const searchMemberList = () => {

            if (!searchMember.value) {
                didSearch.value = false
                return
            }

            didSearch.value = true

            let params = {
                memberName: searchMember.value,
                storeId: curStoreId.value,
                pageSize: 20
            }

            if (vendorChoose.value) {
                params.vendorId = vendorChoose.value
            }

            if (vendorId.value) {
                params.vendorId = vendorId.value
            }

            proxy.$get(`/v3/helpdesk/${identity}/chat/list`, params).then(res => {
                if (res.state == 200) {
                    chatMemberList.list = res.data
                    if (chatMemberList.list.length > 0) {
                        GlobalMemberId.value = chatMemberList.list[0].memberId
                        GlobalStoreId.value = chatMemberList.list[0].storeId
                        minMsgId.value = chatMemberList.list[chatMemberList.list.length - 1].msgId
                        getChatLogList()
                    } else {
                        minMsgId.value = 0
                        msgList.list = []
                    }
                }
            })
        }

        const clearInput = () => {
            searchMember.value = ''
            if (didSearch.value) {
                minMsgId.value = 0
                getMemberChatList()
            }
        }

        const disabledDate = (time) => {
            return time.getTime() > Date.now();
        }

        const change = (index) => {
            cacIdx.value = index
            if (index == 1 && identity == 'admin') {
                showUnderAdmin.value = true
            } else {
                showUnderAdmin.value = false
            }
        }


        onMounted(() => {
            scroll()
            if (identity == 'seller') {
                console.log(isStoreAdmin, 'sss')
                if (isStoreAdmin == 1) {
                    sellerList()
                } else {
                    getMemberChatList()
                }
            } else {
                adminList()
            }

            clientHeight.value = document.body.clientHeight || document.documentElement.clientHeight
        })

        return {
            chatMemberList,
            getChatLogList,
            msgList,
            dateRange,
            msgFilter,
            query,
            chatInit,
            msgReadDone,
            cacIdx,
            clientHeight,
            loadlList,
            loadState,
            adminlist,
            vendorName,
            GlobalMemberId,
            GlobalStoreId,
            clearContent,
            searchMemberList,
            searchMember,
            clearInput,
            isStoreAdmin,
            disabledDate,
            didSearch,
            L,
            change,
            vendorChoose,
            adminVenList,
            identity,
            adminVenChange,
            storeChoose,
            showUnderAdmin,
            adminStoChange,
            isSuper
        }
    }
}
</script>

<style lang="scss">
.sld_chat_history {

    .top_header {
        width: 100%;
        height: 59px;
        padding: 15px 0px;
        border-bottom: 1px solid #F6F6F6;

        .header_title {
            padding-left: 10px;
            border-left: 4px solid #0563FF;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
        }
    }


    .main_acc_top {
        height: 44px;
        border-bottom: 1px solid #F6F6F6;
        display: flex;
        padding-top: 9px;

        .acc_top_item {
            min-width: 129px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            align-self: flex-end;
            color: #666666;
            padding-bottom: 11px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;

            &.csOn {
                border-bottom: 2px solid #198AFF;
                color: #198AFF;
            }
        }
    }

    .middle_bar {
        width: 100%;
        height: 69px;
        display: flex;
        align-items: center;

        .acc_name {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            margin-right: 100px;
            height: 30px;
            display: flex;
            align-items: center;

            .el-input__inner {
                height: 30px;
            }

            .el-input__icon {
                line-height: 30px;
            }

            span {
                display: inline-block;
                max-width: 150px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .chat_content {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;

            input {
                outline: none;
                width: 246px;
                height: 30px;
                background: #FFFFFF;
                border: 1px solid #F6F6F6;
                border-radius: 3px;
            }

            span {
                display: inline-block;
                width: 90px;
            }

            ::-webkit-input-placeholder {
                position: relative;
                left: 7px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
            }

            /* 使用webkit内核的浏览器 */
            :-moz-placeholder {
                position: relative;
                left: 7px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
            }

            /* Firefox版本4-18 */
            ::-moz-placeholder {
                position: relative;
                left: 7px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
            }

            /* Firefox版本19+ */
            :-ms-input-placeholder {
                position: relative;
                left: 7px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
            }

            /* IE浏览器 */
        }

        .chat_date {
            display: flex;
            align-items: center;
            margin-left: 50px;

        }

        .query {
            width: 74px;
            height: 30px;
            background: #0E6FD7;
            border-radius: 3px;
            border: none;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            margin-left: 50px;
        }
    }

    .chat_con {
        display: flex;
        border: 1px solid #F6F6F6;

        .search {
            height: 60px;
            padding-left: 20px;
            display: flex;
            align-items: center;
            position: relative;
            border-bottom: 1px solid #f3f3f3;

            .search_wrap {
                display: flex;
                align-items: center;
                width: 223px;
                height: 28px;
                border-top-left-radius: 13px;
                border-bottom-left-radius: 13px;
                border: 1px solid #F3F3F3;
                border-right: none;
                border-top-right-radius: 13px;
                border-bottom-right-radius: 13px;
            }

            i {
                margin-left: 5px;
                font-size: 15px;
                color: #999999;
                cursor: pointer;

                &.el-icon-close {
                    font-size: 17px;
                    position: absolute;
                    right: 77px;
                    top: 50%;
                    margin-top: -9px;
                }
            }

            input {
                width: 150px;
                height: 26px;
                outline: none;
                border: none;
                margin-left: 5px;
            }

            input::placeholder {

                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
            }

            ::-webkit-input-placeholder {

                margin-left: 20px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
            }

            /* 使用webkit内核的浏览器 */
            :-moz-placeholder {

                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
            }

            /* Firefox版本19+ */
            :-ms-input-placeholder {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
            }

            /* IE浏览器 */

            .button {
                width: 60px;
                height: 28px;
                background: #0E6FD6;
                border-top-right-radius: 13px;
                border-bottom-right-radius: 13px;
                border: none;
                color: #fff;
                font-size: 12px;
                font-family: Microsoft YaHei;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }


        .left_list {
            width: 260px;

            .left_list_item {
                width: 100%;
                height: 52px;
                display: flex;
                align-items: center;
                position: relative;
                cursor: pointer;

                &>img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-left: 20px;

                }


                p {
                    margin-left: 10px;
                }

                .item_icon {
                    position: absolute;
                    right: 20px;
                    display: flex;
                    align-items: center;

                    &>img {
                        margin-left: 10px;
                        display: none;
                    }

                    i {
                        display: inline-block;
                        border-radius: 8px;
                        color: #fff;
                        font-style: normal;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        background-color: $colorMain;
                        padding: 2px 3px;
                        transform: scale(0.9)
                    }
                }

                &:hover {
                    background: #F5F5F5;

                    .item_icon {
                        img {
                            display: block;
                        }
                    }
                }
            }
        }


        .chat_window {
            height: 739px;
            width: 1458px;
            padding: 20px 10px 20px 20px;
            background: #F5F5F5;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;

            .time_line {
                margin: 12px auto;
                line-height: 22px;
                text-align: center;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                width: 149px;
                height: 22px;
                background: #EEEEEE;
                border-radius: 11px;
            }

            #text_center {
                width: 589px;
                height: 104px;
                background: #FFFFFF;
                border-radius: 6px;

                .goods_model {
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    padding-top: 10px;
                    padding-left: 10px;
                }
            }

            .chat_ladder {
                display: flex;
                align-items: flex-end;
                margin-top: 20px;
                margin-bottom: 20px;
                align-items: flex-start;
                box-sizing: border-box;

                &>img {
                    width: 38px;
                    height: 38px;
                    border-radius: 50%;

                    &.user_avatar {
                        margin-left: 10px;
                    }
                }

                .content_right {
                    margin-left: 10px;

                    p {

                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #999999;
                        margin-bottom: 5px;
                    }

                    .content_text {
                        display: inline-block;
                        padding: 11px;
                        position: relative;
                        max-width: 589px;
                        background: #FFFFFF;
                        border-radius: 6px;

                    }

                    .order_type {

                        .goods_info {
                            min-width: 300px;

                            .goods_info_title {

                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                            }
                        }

                        .order_title {
                            display: flex;
                            height: 20px;
                            justify-content: space-between;
                            align-items: flex-start;
                            border-bottom: 1px solid #F2F2F2;
                            margin-bottom: 8px;

                            .order_sn {
                                font-size: 12px;
                                color: #999999;
                                font-family: Microsoft YaHei;

                                i {
                                    font-style: normal;
                                    color: #666666;
                                }
                            }

                            .order_time {
                                color: #999999;
                            }
                        }
                    }

                    .goods_type {
                        min-width: 300px;
                    }

                    .image_type {

                        min-height: 100px;

                        img {
                            max-width: 200px;
                        }

                        .image-slot {
                            width: 200px;
                            height: 200px;
                        }
                    }

                    .text_type {
                        max-width: 360px;
                        word-break: break-all;

                        word-break: break-word;
                        word-wrap: break-word;
                    }
                }

            }


            .user {
                justify-content: flex-start;

                .content_text {
                    background: #FFFFFF;

                    border-radius: 6px;
                }
            }

            .merchant {
                padding-right: 20px;
                justify-content: flex-end;

                p {
                    text-align: right;
                }

                .content_text {
                    .tiny_triangle {
                        position: absolute;
                        right: -9px;
                        width: 0;
                        height: 0;
                        border-right: 14px solid transparent;
                        border-bottom: 13px solid #fff;
                    }

                    background: #fff;
                    border-radius: 6px;
                }

                .msg_read {
                    align-self: flex-end;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                }

                .msg_not_read {
                    align-self: flex-end;
                    height: 100%;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                }
            }
        }

    }
}

.el-range-editor.el-input__inner {
    height: 30px;
}

.el-date-editor .el-range__icon {
    line-height: 25px;
}

.el-date-editor .el-range-separator {
    line-height: 25px;
}

.el-range-editor.is-active,
.el-range-editor.is-active:hover {
    border-color: #F6F6F6;
}

.el-input__inner {
    border-color: #F6F6F6;
}

.el-scrollbar .el-scrollbar__wrap {
    overflow-x: hidden;
}

.el-date-editor .el-range__close-icon {
    line-height: 25px;
}

.el-input__suffix-inner {
    line-height: 30px;
    display: block !important;
}

.empty_data_left {
    height: 300px;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 80px;
    }

    p {
        margin-top: 15px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #D5D5D5;
    }
}

.goods_model {
    display: flex;

    &>img {
        width: 84px;
        height: 84px;
        background: #707070;
        border-radius: 6px;
    }

    .goods_info {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 450px;

        .goods_info_title {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            padding-right: 10px;
        }

        .goods_info_bottom {
            display: flex;
            justify-content: space-between;

            span:first-child {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #e2231a;
            }

            span:nth-child(2) {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #e2231a;
            }
        }
    }
}

#el-scrollbar_top .el-scrollbar__wrap .el-scrollbar__view {
    white-space: nowrap;
    display: flex;
}
</style>